var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Equipment Categories","subtitle":"Browse and Manage your Categories!","badge":this.total}}),_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[(_vm.$has(_vm.perms.EquipmentCategories.Create))?_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.add}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("Add Category")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"md":"9","sm":"12","cols":"12"}},[_c('filter-manager',{ref:"filterManager",attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('v-sheet',{staticClass:"mx-1",attrs:{"height":"28","width":"1","color":"blue-grey lighten-4"}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 categories-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingStates.table,"item-class":function (item) {
        return item.newCategory ? 'new-category-animation' : '';
      },"hide-default-footer":_vm.printing,"disable-pagination":_vm.printing,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{staticStyle:{"flex":"none"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"sm":"auto"}},[_c('i',{class:'equipment-icon fad ' + item.icon})]),_c('v-col',{staticClass:"d-flex align-center col-auto"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)]}},{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_c('i',{staticClass:"fas fa-hashtag fs-12px mr-1"}),_vm._v(_vm._s(item.number)+" ")]}},{key:"item.createdBy",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.createdBy,"icon":""}})]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"date-only":""}})]}},{key:"item.updatedBy",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.updatedBy,"icon":""}})]}},{key:"item.updateDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"date-only":""}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[(_vm.$has(_vm.perms.EquipmentCategories.Update))?_c('v-list-item',{on:{"click":function($event){return _vm.update(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1):_vm._e(),(_vm.$has(_vm.perms.EquipmentCategories.Delete))?_c('v-list-item',{on:{"click":function($event){return _vm.del(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-trash-alt red--text")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('edit-equipment-category',{ref:"editCategory",on:{"save":_vm.onCategorySaved,"close":_vm.onCategoryClosed,"delete":_vm.onCategoryDeleted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }